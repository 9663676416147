import * as React from "react";
import Home from "./Home";

// markup
const IndexPage = () => {
  return (
    <main>
      <Home />
    </main>
  );
};

export default IndexPage;
