import React from "react";
import "../styles/App.css";
import LandingCards from "../components/LandingCards";
import Landing from "../components/Landing";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

function Home() {
  return (
    <>
      <NavBar
        title="Home | Point Blank Moving"
        description="Point Blank Moving, the best professional local moving company in Kansas City. Get your free moving estimate and quote today! Kansas City's premier, five star moving company with dozens of highly rated reviews! The best and most affordable moving company in KC MO (Kansas City, Missouri moving companies). Lee's Summit movers and Overland Park movers now available for estimates."
      />
      <Landing />
      <LandingCards />
      <Footer />
    </>
  );
}

export default Home;
