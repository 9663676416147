import React from "react";
import "../styles/LandingCards.css";
import CardItem from "./CardItem";
import "../styles/Landing.css";
import { StaticImage } from "gatsby-plugin-image";

function LandingCards() {
  return (
    <div className="cards">
      <h1>How can we help you today?</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem text="Hear our story" label="About" path="/About">
              <StaticImage
                className="cards__item__img"
                alt="Hear our story"
                src="../images/about-home.jpg"
                placeholder="blurred"
                loading="eager"
                layout="constrained"
                fadeIn={false}
              />
            </CardItem>
            <CardItem
              text="Find out what services we offer"
              label="Services"
              path="/Services"
            >
              <StaticImage
                className="cards__item__img"
                alt="Find out what services we offer"
                src="../images/services-home.jpg"
                placeholder="blurred"
                loading="eager"
                layout="constrained"
                fadeIn={false}
              />
            </CardItem>
          </ul>
          <ul className="cards__items">
            <CardItem
              text="Read our testimonials"
              label="Reviews"
              path="/Reviews"
            >
              <StaticImage
                className="cards__item__img"
                alt="Read our testimonials"
                src="../images/reviews-home.jpg"
                placeholder="blurred"
                loading="eager"
                layout="constrained"
                fadeIn={false}
              />
            </CardItem>
            <CardItem text="Contact us" label="Contact" path="/Contact">
              <StaticImage
                className="cards__item__img"
                alt="Contact us"
                src="../images/contact.jpg"
                placeholder="blurred"
                loading="eager"
                layout="constrained"
                fadeIn={false}
              />
            </CardItem>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default LandingCards;
