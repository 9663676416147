import React, { useState, useEffect } from "react";
import "../styles/App.css";
import { Button } from "./Button";
import { StaticImage } from "gatsby-plugin-image";
import vid from "../images/vid-0.mp4";
import "../styles/Landing.css";

function Landing() {
  const isBrowser = typeof window !== "undefined";
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);

  useEffect(() => {
    if (!isBrowser) return false;

    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div className="landing-container">
      {width > 960 || !isBrowser ? (
        <video
          className="video"
          src={vid}
          alt="Kansas City's best local moving company. The best KC MO local moving company. Cheap and reliable service. Get a quote now!"
          autoPlay
          loop="loop"
          muted
        ></video>
      ) : (
        <React.Fragment />
      )}
      <h3
        className="landing-header"
        style={{
          fontFamily: "'Special Elite', cursive",
          textShadow: "0 0 5px black, 0 0 5px black",
        }}
      >
        point.blank
      </h3>
      <p style={{ textShadow: "0 0 5px black, 0 0 5px black" }}>
        Let's get things moving!
      </p>
      <div className="landing-btns">
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
          path="/Quote"
        >
          GET A QUOTE
        </Button>
        <Button
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
          path="/Contact"
        >
          GET IN CONTACT
        </Button>
      </div>
      <div className="scroll-down">
        Scroll Down
        <p></p>
      </div>
    </div>
  );
}

export default Landing;
